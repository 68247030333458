

import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import Currency from '@/modules/common/components/currency.vue';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history.service';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import ClipText from '@/modules/common/filters/clip-text.filter';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

export interface TableData {
    id: string,
    name: string,
    price: number,
    roomType: string,
    myHotel: boolean,
    rank?: number | null,
    isCompset: boolean,
    borderColor?: string,
    analysisPrice?: number,
    isBasic?: boolean,
    losRestriction?: boolean | number | null,
    analysisData?: {
        price?: number,
        isBasic?: boolean,
        losRestriction?: boolean | number | null,
    }[],
}

@Component({
    components: {
        Currency,
    },
    filters: {
        PriceFilter,
    },
})
export default class RatesPriceHistoryTable extends Vue {
    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(ClusterCompsetsServiceS)
    private clusterCompsetsService!: ClusterCompsetsService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(RatesPriceHistoryServiceS)
    private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        default: null,
    })
    tableDay!: Day | null;

    @Prop({
        type: String,
    })
    private priceShown!: PRICE_SHOWN;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    hiddenGraphs!: { [k: string]: boolean };

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get isLoading() {
        return this.ratesPriceHistoryService.storeState.loading.isLoading();
    }

    get skeleton() {
        return { 'skeleton skeleton--simple': this.isLoading };
    }

    get currency() {
        return this.ratesPriceHistoryService.currency;
    }

    get compsetId() {
        return this.isClusterPage
            ? this.$route.params.compsetId
            : null;
    }

    get currentHotelId() {
        return this.isClusterPage
            ? Number(this.$route.params.hotelId)
            : this.userService.currentHotelId;
    }

    get isClusterPage() {
        return (
            false
                || this.$route.name!.includes('cluster')
                || this.$route.name!.includes('chain')
        ) && !this.$route.name!.includes('.hotel');
    }

    get scanDate() {
        return moment(this.ratesPriceHistoryService.lastScanDate).format('DD.MM');
    }

    get prevScanDate() {
        const { lastScanDate, dayIndex } = this.ratesPriceHistoryService;

        if (lastScanDate) {
            const d = new Date(lastScanDate);
            d.setDate(d.getDate() - dayIndex);

            return moment(d).format('DD.MM');
        }

        return '';
    }

    get days() {
        const { dayIndex } = this.ratesPriceHistoryService;
        return !dayIndex ? 'Last update' : `-${dayIndex} days`;
    }

    get currentScanDate() {
        const { lastScanDate, dayIndex } = this.ratesPriceHistoryService;

        if (!lastScanDate) return null;

        const currentScanDay = new Date(lastScanDate);
        currentScanDay.setDate(currentScanDay.getDate() - dayIndex);

        return currentScanDay;
    }

    get analysisKeys() {
        return this.ratesAnalysisFiltersService.comparisonValues
            .map(k => ClipText(k.name, 10));
    }

    get mainKey() {
        const v = String(this.ratesAnalysisFiltersService.mainCompareTitle!);
        return ClipText(v, 10);
    }

    private get hotelColors() {
        return this.hotelsService.getHotelsGraphColor(this.compsetId);
    }

    get tableData() {
        const {
            dayIndex,
            ratesSettings,
            hotels,
        } = this.ratesPriceHistoryService;

        this.ratesPriceHistoryService.setDataKey('main');

        if (!hotels || !this.currentScanDate || !ratesSettings) {
            return [] as TableData[];
        }

        const day = this.currentScanDate.getDate();
        const compareRooms = this.ratesAnalysisFiltersService.comparisonValues
            .map((_, docIndex) => this.getAnalysisRooms(dayIndex, docIndex));

        const mainRooms = this.getMainRooms(dayIndex);

        const toTableData = (hotelId: number) => {
            const isMyHotel = hotelId === this.currentHotelId;
            const analysisData = compareRooms
                .map((rooms, docIndex) => this.getAnalysisData(day, rooms[hotelId], hotelId, docIndex));

            this.ratesPriceHistoryService.setDataKey('main');

            if (!mainRooms) {
                return {} as TableData;
            }

            const currentHotelRoom = mainRooms[hotelId];

            const isRoomSoldOut = this.ratesCommonService
                .isSoldOutDay(hotelId, mainRooms, this.priceShown);

            const hotelColor = this.hotelColors[hotelId] || '#000000';

            const hotelName = this.hotelsService.getHotelName(hotelId) || hotelId;

            if (!currentHotelRoom) {
                return {
                    id: String(hotelId),
                    name: hotelName,
                    price: -1,
                    roomType: '-',
                    myHotel: isMyHotel,
                    isBasic: false,
                    isCompset: false,
                    analysisData,

                    borderColor: isMyHotel
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : hotelColor,
                } as TableData;
            }

            return {
                id: String(hotelId),
                name: hotelName,
                isBasic: currentHotelRoom.isBasic,
                myHotel: hotelId === this.currentHotelId,
                isCompset: false,
                analysisData,

                roomType: isRoomSoldOut
                    ? '-'
                    : currentHotelRoom.roomName,

                borderColor: isMyHotel
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : hotelColor,

                price: this.ratesCommonService
                    .switchPrice(currentHotelRoom, this.priceShown),

                losRestriction: this.ratesPriceHistoryService
                    .getLosRestriction(dayIndex, hotelId),
            } as TableData;
        };

        let tablePriceHistoryData = hotels.map(toTableData);

        const roomsWithoutPrice = tablePriceHistoryData
            .reduce((acc, el) => ((el.price && el.price !== -1) ? acc : acc + 1), 0);

        tablePriceHistoryData = tablePriceHistoryData
            .sort((a, b) => +b.price - +a.price)
            .map((el, index) => ({
                ...el,
                rank: (el.price && el.price !== -1)
                    ? tablePriceHistoryData.length - index - roomsWithoutPrice
                    : null,
            }));

        this.ratesPriceHistoryService.setDataKey('main');

        const mainCompsetData = this.getCompsetData(mainRooms, this.priceShown);
        const analysisData = compareRooms
            .map(rooms => this.getCompsetData(rooms, this.priceShown))
            .filter(Boolean)
            .map(compsetData => ({
                price: compsetData.price || -1,
                isBasic: false,
                losRestriction: false,
            }));

        if (mainCompsetData.price) {
            tablePriceHistoryData.push({
                id: mainCompsetData.compset!.type,
                name: String(this.$t(`filterSettings.compset_popup_table.${mainCompsetData.compset!.type}`)),
                isCompset: true,
                price: mainCompsetData.price || -1,
                isBasic: false,
                myHotel: false,
                roomType: '-',
                rank: 0,
                analysisData,
            });
        }

        return tablePriceHistoryData
            .sort((a, b) => b.price - a.price);
    }

    private getCompsetData(rooms: HotelRooms, priceShown: PRICE_SHOWN) {
        const competitorRooms = {
            ...rooms,
        };

        delete competitorRooms[this.currentHotelId!];

        const compset = this.compsetId
            ? this.clusterCompsetsService.getCompsetById(this.compsetId)
            : this.compsetsService.currentCompset;
        const price = this.ratesCommonService.getCompsetPrice(competitorRooms, compset!.type, priceShown);

        return { price, compset };
    }

    getMainRooms(day: number) {
        this.ratesPriceHistoryService.setDataKey('main');

        return this.ratesPriceHistoryService
            .getSuitableRoomByDay(day);
    }

    getAnalysisRooms(day: number, documentIndex = 0) {
        const { comparisonValues, comparisonKey } = this.ratesAnalysisFiltersService;
        const key = comparisonValues[documentIndex]?.name;

        if (!key) return {};

        const diffDays = comparisonKey === 'diffDays'
            ? comparisonValues[documentIndex].value as number
            : 0;

        return this.ratesPriceHistoryService
            .setDataKey(key)
            .getSuitableRoomByDay(day, diffDays);
    }

    getAnalysisData(
        day: number,
        compareRoom: RatesDocumentItemModel | null,
        hotelId: number,
        documentIndex = 0,
    ) {
        if (!compareRoom) {
            return { price: -1, isBasic: false };
        }

        const { priceShown } = this.documentFiltersService;
        const { isBasic } = compareRoom;

        const price = this.ratesCommonService
            .switchPrice(compareRoom, priceShown);

        const losRestriction = this.ratesAnalysisService
            .getHotelLosRestriction(day as Day, hotelId, documentIndex);

        return {
            isBasic,
            price,
            losRestriction,
        };
    }

    toggleGraph(hotel: TableData) {
        this.$emit('toggle-hotel', hotel);
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }
}
