var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tableData)?_c('div',{staticClass:"table"},[_c('p',{staticClass:"scanned-at"},[_vm._v(" Scanned at "+_vm._s(_vm.scanDate)+" ("+_vm._s(_vm.days)+" - "+_vm._s(_vm.prevScanDate)+") ")]),_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title"},[_vm._v("Name")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'name',class:{
                'hotel-name': true,
                'hotel-name--median' : hotel.isCompset,
                'hotel-name--value': true,
                'hotel-name--my': hotel.myHotel,
                'hotel-name--hidden': _vm.hiddenGraphs[hotel.id],
            },on:{"click":function($event){return _vm.toggleGraph(hotel)}}},[(!_vm.isLoading)?_c('div',{class:{
                    'graph-legend': true,
                    'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                    'graph-legend--transparent': hotel.isCompset,
                },style:({
                    '--indicator-color': hotel.borderColor,
                })}):_vm._e(),_c('p',{staticClass:"hotel-name__value",class:{ ..._vm.skeleton },attrs:{"title":hotel.name}},[_vm._v(" "+_vm._s(hotel.name)+" ")])])})],2),_c('div',{staticClass:"table__column rank"},[_c('p',{staticClass:"title"},[_vm._v("Rank")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'rank',class:{
                'rank__value': true,
                'rank__value--my': hotel.myHotel,
                'rank__value--median': hotel.isCompset
            }},[_c('p',[_c('span',{class:_vm.skeleton},[_vm._v(_vm._s(hotel.rank || '---'))])])])})],2),_c('div',{staticClass:"table__column basic"},[_c('p',{staticClass:"title"}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                'basic__value': true,
                'basic__value--median': hotel.isCompset,
            }},[(hotel.isBasic)?_c('img',{staticClass:"basic__icon",attrs:{"src":_vm.bookingBasicIcon}}):_vm._e()])})],2),_c('div',{staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.isAnalysisPage ? _vm.mainKey : 'Price')+" ")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                'price__value': true,
                'price__value--my': hotel.myHotel,
                'price__value--median': hotel.isCompset,
            }},[(hotel.price && hotel.price !== -1)?_c('p',[(!_vm.isLoading)?_c('Currency',{attrs:{"currency":_vm.currency}}):_vm._e(),_c('span',{class:_vm.skeleton},[_vm._v(" "+_vm._s(_vm._f("PriceFilter")(hotel.price))+" ")]),(hotel.myHotel && hotel.losRestriction)?_c('span',{class:_vm.skeleton},[_vm._v(" "+_vm._s(`(LOS${hotel.losRestriction})`)+" ")]):_vm._e(),(hotel.losRestriction && !hotel.myHotel)?[_c('span',{staticClass:"los-wrap"},[_c('span',{staticClass:"background"}),_c('i',{staticClass:"icon-los2"},[_c('span',[_vm._v(_vm._s(hotel.losRestriction))])])])]:_vm._e()],2):(hotel.price === 0)?_c('p',[_c('span',{class:_vm.skeleton},[_vm._v("Sold out")])]):_c('p',[_c('span',{class:_vm.skeleton},[_vm._v("---")])])])})],2),(_vm.isAnalysisPage)?_vm._l((_vm.analysisKeys),function(analysisKey,docIndex){return _c('div',{key:docIndex + 'analysisKey',staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(analysisKey)+" ")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'price',class:{
                    'price__value': true,
                    'price__value--my': hotel.myHotel,
                    'price__value--median': hotel.isCompset,
                }},[(hotel.analysisData[docIndex] && hotel.analysisData[docIndex].price && hotel.analysisData[docIndex].price !== -1)?_c('p',[(!_vm.isLoading)?_c('Currency',{attrs:{"currency":_vm.currency}}):_vm._e(),_c('span',{class:_vm.skeleton},[_vm._v(" "+_vm._s(_vm._f("PriceFilter")(hotel.analysisData[docIndex].price))+" ")]),(hotel.myHotel && hotel.analysisData[docIndex].losRestriction)?_c('span',{class:_vm.skeleton,domProps:{"textContent":_vm._s(`(LOS${hotel.analysisData[docIndex].losRestriction})`)}}):_vm._e(),(hotel.analysisData[docIndex].losRestriction && !hotel.myHotel)?_c('span',{staticClass:"los-wrap"},[_c('span',{staticClass:"background"}),_c('i',{staticClass:"icon-los2"},[_c('span',{domProps:{"textContent":_vm._s(hotel.analysisData[docIndex].losRestriction)}})])]):_vm._e()],1):(hotel.analysisData[docIndex] && hotel.analysisData[docIndex].price === 0)?_c('p',[_c('span',{class:_vm.skeleton},[_vm._v("Sold out")])]):_c('p',[_c('span',{class:_vm.skeleton},[_vm._v("---")])])])})],2)}):_vm._e(),_c('div',{staticClass:"table__column room-type"},[_c('p',{staticClass:"title"},[_vm._v("Room name")]),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'roomType',class:{
                'room-type__value': true,
                'room-type__value--my': hotel.myHotel,
                'room-type__value--median': hotel.isCompset,
            },attrs:{"title":hotel.roomType}},[_c('p',[_c('span',{class:_vm.skeleton},[_vm._v(_vm._s(hotel.isCompset ? '---' : hotel.roomType))])])])})],2)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }