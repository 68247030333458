
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-all.service';

import { PRICE_SHOWN } from '@/modules/rates/constants';
import type Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Currency from '@/modules/common/components/currency.vue';
import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from '../rates-price-history-common.service';
import RatesCommonService, { RatesCommonServiceS } from '../../rates/rates-common.service';

export interface TableData {
    name: string,
    price: number,
    diff: number,
    myHotel?: boolean,
    borderColor?: string,
}

@Component({
    filters: { PercentFilter, PriceFilter },
    components: { Currency },
})
export default class RatesPriceHistoryTableAll extends Vue {
    @Inject(RatesPriceHistoryServiceS)
    private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Inject(RatesPriceHistoryAllServiceS)
    private ratesPriceHistoryAll!: RatesPriceHistoryAllService;

    @Inject(RatesPriceHistoryCommonServiceS)
    private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Inject(RatesAllServiceS)
    private ratesAllService!: RatesAllService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Prop({
        type: String,
        required: true,
    })
    private priceShown!: PRICE_SHOWN;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    hiddenGraphs!: { [k: string]: boolean };

    private get chartColors() {
        return this.userSettingsService.chartColors || [];
    }

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    get isChainPage() {
        return (this.$route.name!.includes('chain') && !this.$route.name!.includes('.hotel'))
            || (this.$route.name!.includes('cluster') && !this.$route.name!.includes('.hotel'));
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get scanDate() {
        return moment(this.ratesPriceHistoryService.lastScanDate).format('DD.MM');
    }

    get prevScanDate() {
        const { lastScanDate, dayIndex } = this.ratesPriceHistoryService;

        if (lastScanDate) {
            const d = new Date(lastScanDate);
            d.setDate(d.getDate() - dayIndex);

            return moment(d).format('DD.MM');
        }

        return '';
    }

    get days() {
        const { dayIndex } = this.ratesPriceHistoryService;
        return !dayIndex ? 'Last update' : `-${dayIndex} days`;
    }

    get tableData() {
        const { providers } = this.ratesPriceHistoryAll;
        if (!providers) {
            return [];
        }

        const tablePriceHistoryData: TableData[] = providers.map((provider: string, index: number) => {
            const { lastScanDate, dayIndex } = this.ratesPriceHistoryService;

            if (lastScanDate === null) {
                return {} as TableData;
            }

            const providerItem = this.ratesPriceHistoryAll.getSuitableProviderByDay(dayIndex);

            if (!providerItem) {
                return {
                    id: provider,
                    name: this.providersService.getProviderLabel(provider)
                        || this.$t(`filterSettings.provider.${provider}`),

                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                } as TableData;
            }
            if (!providerItem[provider]) {
                return {
                    id: provider,
                    name: this.providersService.getProviderLabel(provider)
                        || this.$t(`filterSettings.provider.${provider}`),

                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                } as TableData;
            }

            const averagePrice = this.averagePrice(providerItem);
            const price = this.ratesCommonService.switchPrice(providerItem[provider].room, this.priceShown)!;

            return {
                id: provider,
                name: this.providersService.getProviderLabel(provider)
                    || this.$t(`filterSettings.provider.${provider}`),
                price,
                diff: (provider !== 'average')
                    ? this.priceDiff(price, averagePrice)
                    : -1,
                borderColor: provider === 'average'
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.chartColors[index],
                myHotel: provider === 'average',
            } as TableData;
        });

        return tablePriceHistoryData.sort((a, b) => b.price - a.price);
    }

    private averagePrice(items: {[provider: string]: RatesDocumentItemAllModel}) {
        if (!items.average) {
            return 0;
        }

        const price = this.ratesCommonService.switchPrice(items.average.room, this.priceShown)!;

        return Number.isNaN(price) ? 0 : price;
    }

    private priceDiff(myPrice: number, comparePrice: number) {
        if (myPrice === 0 || myPrice === -1) return '';
        return this.ratesAllService.priceDiff(myPrice, comparePrice);
    }

    toggleGraph(hotel: TableData) {
        this.$emit('toggle-hotel', hotel);
    }
}
