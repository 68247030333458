var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{ref:"modalWrapper",attrs:{"options":_vm.modalOptions},on:{"onClose":_vm.resetPopupData},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('RatesPriceHistoryHeader')]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"main"},[_c('div',{staticClass:"main__header"},[_c('RatesPriceHistoryFilters',{attrs:{"isCluster":_vm.isCluster}})],1),(_vm.isAnalysisPage)?_c('div',{staticClass:"comparison-filter"},[_c('span',[_vm._v(_vm._s(_vm.$t(`comparisonTo`)))]),_c('span',{staticClass:"comparison-filter__selected",domProps:{"textContent":_vm._s(_vm.compareLabel)}})]):_vm._e(),_c('div',{class:{
                    'main__graph': true,
                    'main__graph--empty': _vm.isNoData,
                    'skeleton-container': _vm.skeleton
                }},[(_vm.chartData)?_c('CustomGraph',{class:{'skeleton skeleton--gray': _vm.skeleton},attrs:{"type":'line',"isTooltip":Boolean(_vm.chartData.labels.length),"haveFixedTooltip":true,"options":_vm.options,"chartData":_vm.chartData,"defaultTooltipPos":_vm.lastTooltipPos,"onTooltipClick":_vm.handleTooltipClick},on:{"current-day":_vm.setTooltipDay,"setDay":_vm.setDay,"tooltip-state-changed":_vm.setTooltipElement},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"price-history-tooltip"})]},proxy:true}],null,false,1274193594)}):_c('div',{staticClass:"graph-placeholder",class:{'skeleton skeleton--gray': _vm.skeleton}}),(!_vm.skeleton)?_c('p',{staticClass:"last-scan"},[_vm._v(" Last update ")]):_vm._e()],1),_c('RatesPriceHistoryTooltip',{attrs:{"focusElement":_vm.tooltipFocusElement,"day":_vm.tooltipDay,"ignoreHotels":_vm.hiddenGraphs,"priceShown":_vm.priceShown}}),_c('div',{class:{
                    'content-wrapper': true,
                    'skeleton-container': _vm.skeleton,
                }},[_c('div',{staticClass:"main__table"},[(_vm.filterAll && !_vm.skeleton)?_c('RatesPriceHistoryTableAll',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"priceShown":_vm.priceShown,"tableDay":_vm.tableDay},on:{"toggle-hotel":_vm.toggleGraph}}):(!_vm.filterAll && !_vm.skeleton)?_c('RatesPriceHistoryTable',{attrs:{"hiddenGraphs":_vm.hiddenGraphs,"priceShown":_vm.priceShown,"tableDay":_vm.tableDay},on:{"toggle-hotel":_vm.toggleGraph}}):(_vm.skeleton)?_c('div',{class:{
                            'skeleton-wrap': true,
                            'skeleton skeleton--gray': _vm.skeleton,
                        }}):_vm._e()],1),(!_vm.filterAll && _vm.hideStatistics && _vm.isStatisticsAvailable)?_c('div',{staticClass:"statistics"},[(_vm.occupancy)?_c('Occupancy',{attrs:{"type":"extended","value":_vm.occupancy}}):_vm._e(),(_vm.demand)?_c('Demand',{attrs:{"type":"extended","value":_vm.demand}}):_vm._e()],1):_vm._e()])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[_c('TogglePrices',{model:{value:(_vm.priceShown),callback:function ($$v) {_vm.priceShown=$$v},expression:"priceShown"}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }