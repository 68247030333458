var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tableData)?_c('div',{staticClass:"table"},[_c('p',{staticClass:"scanned-at"},[_vm._v(" Scanned at "+_vm._s(_vm.scanDate)+" ("+_vm._s(_vm.days)+" - "+_vm._s(_vm.prevScanDate)+") ")]),_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title"},[_vm._v("Source Name")]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'name',class:{
                'hotel-name': true,
                'hotel-name__median' : item.isCompset
            },on:{"click":function($event){return _vm.toggleGraph(item)}}},[_c('div',{class:{
                    'graph-legend': true,
                    'graph-legend--hidden': _vm.hiddenGraphs[item.id],
                    'graph-legend--transparent': item.isCompset,
                },style:({
                    '--indicator-color': item.borderColor,
                })}),_c('p',{staticClass:"hotel-name__value",class:{'hotel-name__my' : item.myHotel},attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])])})],2),_c('div',{staticClass:"table__column price"},[_c('p',{staticClass:"title"},[_vm._v("Price")]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'price',staticClass:"price__value",class:{'price__value__my' : item.myHotel, 'price__value__median' : item.isCompset}},[(item.price !== -1 && item.price !== 0)?_c('p',[_c('Currency',{attrs:{"currency":_vm.currency}}),_vm._v(" "+_vm._s(_vm._f("PriceFilter")(item.price))+" ")],1):(item.price === 0)?_c('p',[_vm._v("Sold out")]):_c('p',[_vm._v("---")])])})],2),_c('div',{staticClass:"table__column room-type"},[_c('p',{staticClass:"title"},[_vm._v("% Diff")]),_vm._l((_vm.tableData),function(item,i){return _c('div',{key:i + 'roomType',staticClass:"room-type__value",class:{'room-type__value__my' : item.myHotel, 'room-type__value__median' : item.isCompset}},[((item.diff || item.diff === 0) && item.diff !== -1)?_c('p',[_vm._v(" "+_vm._s(_vm._f("PercentFilter")(item.diff))+"% ")]):_c('p',[_vm._v("---")])])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }